import React from "react";
import "./testimonial.css";
import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";

import img from "../../assests/images/testimonial01.png";

const Testimonials = () => {
  const settings = {
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
  };
  return (
    <section>
      <Container>
        <Col lg="10" md="12" className="m-auto">
          <div className="testimonial__wrapper testimonial d-flex justify-content-between align-items-center ">
            <div className="testimonial__img">
              <img src={img} alt="" />
            </div>

            <div className="testimonial__content w-50">
              <h2 className="mb-4">Our Students Voice</h2>

              <Slider {...settings}>
                <div>
                  <div className="single__testimonial">
                    <h6 className="mb-3 fw-bold">
                      Excellent course of materials
                    </h6>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Facilis saepe id voluptas molestiae. Aperiam corrupti
                      voluptas earum at molestiae neque!
                    </p>

                    <div className="student__info mt-4">
                      <h6 className="fw-bold">Jhon Doe</h6>
                      <p>California, United State</p>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="single__testimonial">
                    <h6 className="mb-3 fw-bold">
                      Excellent course of materials
                    </h6>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Facilis saepe id voluptas molestiae. Aperiam corrupti
                      voluptas earum at molestiae neque!
                    </p>

                    <div className="student__info mt-4">
                      <h6 className="fw-bold">Jhon Doe</h6>
                      <p>California, United State</p>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="single__testimonial">
                    <h6 className="mb-3 fw-bold">
                      Excellent course of materials
                    </h6>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Facilis saepe id voluptas molestiae. Aperiam corrupti
                      voluptas earum at molestiae neque!
                    </p>

                    <div className="student__info mt-4">
                      <h6 className="fw-bold">Jhon Doe</h6>
                      <p>California, United State</p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </Col>
      </Container>
    </section>
  );
};

export default Testimonials;
