import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { Logo } from "../../Images";
import certificateData from "../../data";
import axios from "../../axios";

function Dashoard() {
  const [certificates, setCertificates] = useState([]);

  const handleCertLink = (token) => {
    window.location.href = `/certificate/${token}`;
  };

  useEffect(() => {
    try {
      const getCerts = async () => {
        await axios.get("/certs/post").then((res) => {
          console.log(res.data);
          setCertificates(res.data.data);
        });
      };

      getCerts();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="dashboard">
      <div className="dashboard__header">admin dashboard</div>

      <a href="/add-certificates">
        <div className="dashboard__container__btn__add">add certificate</div>
      </a>
      <div className="dashboard__recents">
        {certificates.slice(0, 4).map((certificate, index) => (
          <div
            className="dashboard__cert__card"
            key={index}
            onClick={() => handleCertLink(certificate.token)}
          >
            <div className="certificate__header">
              <div className="certificate__header__logo">
                <img src={Logo} alt="" />
              </div>

              <div className="certificate__header__name">
                renaissance university, Enugu. <br />
                department of {certificate.department}
              </div>
            </div>

            <div className="certificate__body">
              <div className="certificate__body__date">{certificate.date}</div>

              <div className="certificate__body__header">
                <h3>certification</h3>
              </div>

              <div className="certificate__body__content">
                <p>
                  This is to certify that <span>{certificate.name}</span> has
                  successfully completed their studies at Renaissance
                  University, Enugu. They have fulfilled all the necessary
                  requirements for graduation and have been awarded a Degree in{" "}
                  <span>{certificate.program}</span> .
                </p>
              </div>

              <div className="certificate__footer">
                <section>
                  <div className="certificate__footer__registrar">
                    {certificate.registrar
                      ? certificate.registrar
                      : "Professor Williams"}
                  </div>
                  <p>Registrar</p>
                </section>

                <section className="certificate__token">
                  {certificate.token}
                </section>
              </div>
            </div>
          </div>
        ))}
      </div>

      {certificates.length >= 4 && (
        <a href="/all-certificates">
          <div className="dashboard__container__btn__showall">see all</div>
        </a>
      )}
    </div>
  );
}

export default Dashoard;
