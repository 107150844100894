import React from "react";
import { SearchIcon } from "../../Icons";
import "./Verify.css";
import { useState } from "react";

function Verify() {
  const [query, setQuery] = useState("");

  const handleSearch = () => {
    window.location.href = `/certificate/${query}`;
  };
  return (
    <div className="verify">
      <div className="verify__header">verify student's certificate</div>

      <div className="verify__verify__container">
        <p>
          <input
            type="verify"
            name=""
            id=""
            placeholder="enter certificate code..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </p>{" "}
        <p onClick={handleSearch}>
          <SearchIcon />
        </p>
      </div>
    </div>
  );
}

export default Verify;
