import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/auth/Login";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import HeroSection from "./components/Hero-Section/HeroSection";
import Verify from "./pages/verify/Verify";
import Certificate from "./pages/certificate/Certificate";
import Dashoard from "./pages/Dashboard/Dashoard";
import AllCerificates from "./pages/certificate/AllCertificates";
import AddCertificate from "./pages/certificate/AddCertificate";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";

function App() {
  const { user } = useContext(AuthContext);

  return (
    <div ClassName="app">
      <Header />
      <HeroSection />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verify-cert" element={<Verify />} />
        <Route path="/certificate/:token" element={<Certificate />} />
        <Route path="/dashboard" element={user && <Dashoard />} />
        <Route path="/all-certificates" element={user && <AllCerificates />} />
        <Route path="/add-certificates" element={user && <AddCertificate />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
