const certificateData = [
  {
    department: "Eduation",
    date: new Date(),
    name: "Don martins",
    college: "colpas",
    program: "computer science",
    token: "4547UFGS2G",
    registrar: "Adam williams",
  },

  {
    department: "Eduation",
    date: new Date(),
    name: "emeanaua martins",
    college: "colpas",
    program: "computer science",
    token: "4547UFGS2G",
    registrar: "Adam williams",
  },

  {
    department: "Eduation",
    date: new Date(),
    name: "emeanaua martins",
    college: "colpas",
    program: "computer science",
    token: "4547UFGS2G",
    registrar: "Adam williams",
  },

  {
    department: "Eduation",
    date: new Date(),
    name: "emeanaua martins",
    college: "colpas",
    program: "computer science",
    token: "4547UFGS2G",
    registrar: "Adam williams",
  },

  {
    department: "Eduation",
    date: new Date(),
    name: "emeanaua martins",
    college: "colpas",
    program: "computer science",
    token: "4547UFGS2G",
    registrar: "Adam williams",
  },

  {
    department: "Eduation",
    date: new Date(),
    name: "emeanaua martins",
    college: "colpas",
    program: "computer science",
    token: "4547UFGS2G",
    registrar: "Adam williams",
  },

  {
    department: "Eduation",
    date: new Date(),
    name: "emeanaua martins",
    college: "colpas",
    program: "computer science",
    token: "f032eb49-8",
    registrar: "Adam williams",
  },
];

export default certificateData;
