import React, { useContext, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import "./Login.css";
import { loginCall } from "../../apiCalls";
import CircularProgress from "@mui/material/CircularProgress";

function Login() {
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [SuccessAlerts, setSuccessAlerts] = useState("");
  const [ErrorAlerts, setErrorAlerts] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const email = useRef();
  const password = useRef();

  const { dispatch } = useContext(AuthContext);

  const handleSubmit = (e) => {
    setIsFetching(true);
    e.preventDefault();
    try {
      loginCall(
        {
          email: email.current.value,
          password: password.current.value,
        },
        dispatch
      ).then((res) => {
        console.log(res);
        // setSuccessAlert(true);
        window.location.replace("/");
      });
    } catch (err) {
      console.log(err.response);
      setErrorAlert(true);
      setErrorText(err.response.data.message);
      setIsFetching(false);
    }
  };

  const close = () => {
    setErrorAlert(false);
    setSuccessAlert(false);
  };
  // const initialValues = {
  //   email: "",
  //   password: "",
  // };

  // const [user, setUser] = useState(initialValues);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setUser({
  //     ...user,
  //     [name]: value,
  //   });
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const post = await axios.post("/api/v1/users/login", user).then((res) => {
  //       console.log(res);
  //     });

  //     post();
  //   } catch (error) {
  //     console.log(error.response.data.message);
  //   }
  // };
  return (
    <>
      {successAlert ? (
        <SuccessAlerts close={close} />
      ) : errorAlert ? (
        <ErrorAlerts message={errorText} close={close} />
      ) : null}
      <div className="login">
        <div className="login__container">
          <div className="login__container__form">
            <h4> login</h4>

            <form>
              {/* <div className="email">
                <input
                  type="email"
                  placeholder="example@gmail.com"
                  value={user.email}
                  onChange={handleInputChange}
                  name="email"
                />
              </div>
              <div className="password">
                <input
                  type="password"
                  placeholder="password"
                  value={user.password}
                  onChange={handleInputChange}
                  name="password"
                />
              </div> */}

              <div className="email">
                <input
                  type="email"
                  placeholder="example@gmail.com"
                  className="loginInput"
                  ref={email}
                />
              </div>

              <div className="password">
                <input type="password" placeholder="password" ref={password} />
              </div>

              <div className="login__container__form__create">
                {/* <h5>don't have an account? </h5> */}
                {/* <a href="/adminregister"> sign up</a> */}
              </div>

              <div className="login__container__btn" onClick={handleSubmit}>
                {isFetching ? (
                  <CircularProgress size={16} color="inherit" />
                ) : (
                  "submit"
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
