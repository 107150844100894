import React, { useState, useEffect } from "react";
import { Logo } from "../../Images";
import "./Certificate.css";
import html2pdf from "html2pdf.js";
import axios from "../../axios";
import { useParams } from "react-router-dom";

const Certificate = () => {
  const pdfRef = React.createRef();
  const [certificate, setCertificate] = useState({});
  const { token } = useParams();
  const [success, setSuccess] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    try {
      const getCetificate = async () => {
        await axios.get(`/certs/post/${token}`).then((res) => {
          setCertificate(res.data.data[0]);
          setSuccess(true);
          setIsFetching(false);

          if (res.data.data.length === 0) {
            setSuccess(false);
          }
        });
      };
      getCetificate();
    } catch (error) {
      console.log(error);
      setSuccess(false);
      setIsFetching(false);
    }
  }, []);

  const handleConvertToPdf = () => {
    const element = pdfRef.current;
    const opt = {
      margin: 0,
      filename: "document.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
    };

    html2pdf().set(opt).from(element).save();
  };

  return (
    <div>
      {isFetching ? (
        <div className="center">Loading...</div>
      ) : (
        <>
          {success && !isFetching ? (
            <>
              <div className="certificate" ref={pdfRef}>
                {/* Watermark */}
                <div className="certificate__watermark">Confirmed</div>

                <div className="certificate__header">
                  <div className="certificate__header__logo">
                    <img src={Logo} alt="" />
                  </div>

                  <div className="certificate__header__name">
                    Renaissance University, Enugu. <br />
                    Department of {certificate?.college}
                  </div>
                </div>

                <div className="certificate__body">
                  <div className="certificate__body__date">
                    {certificate?.date}
                  </div>

                  <div className="certificate__body__header">
                    <h3>Certification</h3>
                  </div>

                  <div className="certificate__body__content">
                    <p>
                      This is to certify that <span>{certificate?.name}</span>{" "}
                      has successfully completed studies at Renaissance
                      University, Enugu having fulfilled all the necessary
                      requirements for graduation and have been awarded a Degree
                      in <span>{certificate?.department}</span>.
                    </p>
                  </div>

                  <div className="certificate__footer">
                    <section>
                      <div className="certificate__footer__registrar">
                        {certificate?.registrar
                          ? certificate?.registrar
                          : "Professor Williams"}
                      </div>
                      <p>Registrar</p>
                    </section>

                    <section className="certificate__token">
                      {certificate?.token}
                    </section>
                  </div>
                </div>
              </div>
              <div
                className="dashboard__container__btn__showall cert"
                onClick={handleConvertToPdf}
              >
                Download Certificate
              </div>
            </>
          ) : (
            <div className="center">No data found</div>
          )}
        </>
      )}
    </div>
  );
};

export default Certificate;
