import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./features.css";

const FeatureData = [
  {
    title: "Convenient",
    desc: "Online certificate verification allows you to verify the authenticity of a certificate from anywhere, at any time. You can simply access the verification platform through a computer or mobile device with an internet connection.",
    icon: "ri-draft-line",
  },

  {
    title: "Real Time",
    desc: "Online verification systems often provide real-time verification results, allowing immediate confirmation of a certificate's validity. This is particularly useful in time-sensitive situations or when making quick decisions based on the verification outcome",
    icon: "ri-discuss-line",
  },

  {
    title: "Accessibility",
    desc: "Online certificate verification platforms can be accessed globally, allowing organizations and individuals from different locations to verify certificates easily. This is particularly beneficial in cases where certificates need to be verified across borders or in international contexts.",
    icon: "ri-contacts-book-line",
  },
];

const Features = () => {
  return (
    <section>
      <Container>
        <Row>
          {FeatureData.map((item, index) => (
            <Col lg="4" md="6" key={index}>
              <div className="single__feature text-justify px-4">
                <h2 className="mb-3">
                  <i class={item.icon}></i>
                </h2>
                <h6>{item.title}</h6>
                <p>{item.desc}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Features;
