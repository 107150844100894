import React, { useState } from "react";
import "./AddCertificate.css";
import { v4 as uuidv4 } from "uuid";
import axios from "../../axios";
import { CircularProgress } from "@mui/material";

function AddCertificate() {
  const [name, setName] = useState("");
  const [college, setCollege] = useState("");
  const [department, setDepartment] = useState("");
  const [date, setDate] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  uuidv4();

  const truancateToken = uuidv4().slice(0, 8);

  const handleSubmit = async () => {
    setIsFetching(true);
    try {
      const payLoad = {
        name: name,
        college: college,
        department: department,
        date: date,
        token: truancateToken,
      };

      console.log(payLoad);

      await axios.post("/certs/post", payLoad).then((res) => {
        console.log(res);
        window.location.href = "/dashboard";
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="addcertificate">
      <div className="addcertificate__header">Add a certificate</div>

      <form>
        <div className="email">
          <input
            type="text"
            placeholder="Enter name"
            className="loginInput"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="email">
          <input
            type="text"
            placeholder="Enter college"
            className="loginInput"
            value={college}
            onChange={(e) => setCollege(e.target.value)}
          />
        </div>

        <div className="email">
          <input
            type="text"
            placeholder="Enter department"
            className="loginInput"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
          />
        </div>

        <div className="email">
          <input
            type="date"
            className="loginInput"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>

        <div
          className="dashboard__container__btn__showall"
          onClick={handleSubmit}
        >
          {isFetching ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            "submit"
          )}
        </div>
      </form>
    </div>
  );
}

export default AddCertificate;
