import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";

import chooseImg from "../../assests/images/why-choose-us.webp";
import "./choose-us.css";

import ReactPlayer from "react-player";

const ChooseUs = () => {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <section>
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="choose__content">
              <h2>Why Choose Us</h2>
              <p>
                online certificate verification offers a convenient, secure, and efficient way to verify the authenticity of certificates, benefiting both certificate holders and verifiers in terms of time, cost, and accessibility.
              </p>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="choose__img">
              <img src={chooseImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ChooseUs;
